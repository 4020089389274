<template>
  <div class="home_main">
    <!-- height="600px" -->
    <one-img-carousel :list="homeCarouselList" :isContent="false" v-if="homeCarouselList.length"></one-img-carousel>
    <div style="padding: 0 3%;">
      <one-row-title title="技术服务" />
      <technical-service />
      <div class="space">
        <one-row-title title="优质服务" />
        <quality-service />
        <one-row-title title="资讯动态" />
        <consult-trends :list="consultationTrends.companyNewsList" bigTitle="公司新闻" :trendsType="1" />
        <consult-trends :list="consultationTrends.industryNewsList" bigTitle="行业动态" :trendsType="2" />
        <consult-trends :list="consultationTrends.noticeList" bigTitle="通知公告" :trendsType="3" />
        <consult-trends :list="consultationTrends.summaryList" bigTitle="项目总结" :trendsType="4" />
        <one-row-title title="荣誉" />
        <smooth-carousel :list="honorCarouselList" :isTitle="true" className="home_honors" v-if="honorCarouselList.length"
          :number="4" :loop="true" :speed="5000" />
        <one-row-title title="资料下载" />
        <data-down />
      </div>
      <one-row-title title="安恪宗旨" />
      <home-purpose style="margin-top: 10px;" />
      <one-row-title title="合作伙伴" />
      <smooth-carousel :list="partnersList" v-if="partnersList.length" :isPreview="false" :isTitle="false"
        className="home_partner" imgHeight="180px" :isBreakpoints="true" :number="partnerNum" imgWidth="auto"
        ref="partnersRef" />

    </div>

  </div>
</template>
<script>
import { consultationPagePost, honorPagePost, partnersPagePost, rotationPagePost } from '@/api/index';
import { useSimpleApi } from '@/utils';
const modulesFiles = require.context("./components", true, /\.vue$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default {
  name: "Home",
  components: {
    ...modules,
  },
  created() {
    this.getHomeCarousel()
    this.getHonor()
    this.getPartners()
    this.getConsultationTrendsList(1, 'companyNewsList')
    this.getConsultationTrendsList(2, 'industryNewsList')
    this.getConsultationTrendsList(3, 'noticeList')
    this.getConsultationTrendsList(4, 'summaryList')
  },
  data() {
    return {
      homeCarouselList: [],
      homeCarouselHeight: "",
      honorCarouselList: [],
      partnersList: [],
      partnerNum: 3,
      company: [
        "企业从事于：互联网整合营销，互联网软件开发，微信公众号，小程序，微商城开发，提供技术支持，是所创业型服务研发公司。",
        "核心理念：打造移动电子商务平台 ，提供一键式部署，实现简单可靠稳定的商务平台。",
        "团队作风：我们来自不同地区，因梦想相聚，组建团队，每个团队成员如同人的五官，缺一不可。",
        "企业精神：以实力为盾，自信为矛。我们坚信成功靠朋友，成长靠对手，成就靠团体。",
      ],
      consultationTrends: {
        companyNewsList: [],
        industryNewsList: [], // 行业动态
        noticeList: [], // 通知公告
        summaryList: [] // 项目总结
      }
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.getHomeCarouselHeight();
    }, false);
  },
  methods: {
    getHomeCarousel() {
      useSimpleApi(rotationPagePost, (data) => {
        const imgList = data.rows[0]?.picture ?? []
        this.homeCarouselList = imgList
      })
    },
    getHonor() {
      useSimpleApi(honorPagePost, (data) => {
        this.honorCarouselList = data.rows
      })
    },
    getPartners() {
      useSimpleApi(partnersPagePost, (data) => {
        console.log(data.rows[0].images, 44);
        this.partnersList = data.rows[0]?.images ?? []
      })
    },
    getConsultationTrendsList(trendsType, listField) {
      useSimpleApi(consultationPagePost, (data) => {
        this.consultationTrends[listField] = data.rows
      }, {
        trendsType, pages: {
          pageNum: 1, pageSize: 3
        },
        releaseType: 1 // 区分 是否立刻上传
      })
    },
    titleClickJumpPage(i) {
      console.log(i);
    },
    getHomeCarouselHeight() {
      this.$nextTick(() => {
        this.homeCarouselHeight = this.$refs.carouselImg && this.$refs.carouselImg.length ? this.$refs.carouselImg[0].height : "";//关键的一步
      });
    }
  },

  computed: {
    specArrowImg() {
      return this.$store.state.website.specArrowImg;
    },
  },
};
</script>

<style scoped lang="scss">
.home_main {
  .space {
    padding: 0 30px;
  }
}

.about_me_p {
  font-size: 1.25em;
  font-weight: normal;
  padding: 0 20px;
  margin: 10px;
}

.about_me {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
}

/* 屏幕设备适配 max-device-width: 400px */
@media screen and (max-device-width: 415px) {

  .head-label {
    display: flex;
    margin-top: 10px;
  }

  .about_me {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }
}
</style>
