import '@/components'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/dist/css/swiper.css'
import 'swiper/dist/js/swiper'
import Vue from 'vue'
import VideoPlayer from 'vue-video-player'
import Layout from './Layout'
import router from './router'
import store from './store'
import './styles/colors.scss'
import './styles/index.scss'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(ElementUI)

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(Layout),
})
