const state = {
  specArrowImg: '',
  screenWidth: '',
  technicalServiceList: [
    { id: 1, title: '完整性管理' },
    { id: 2, title: '设备管理系统' },
    { id: 3, title: '设备管理提升' },
    { id: 4, title: '防腐蚀技术方案' },
    { id: 5, title: '大修技术服务' },
    { id: 6, title: '大修管理承包' },
    { id: 7, title: '可靠性服务' },
    { id: 8, title: '工艺管理体系' },
    { id: 9, title: '水务技术服务' },
    { id: 10, title: '供应商评估' },
    { id: 11, title: '安全合规管理' },
    { id: 12, title: '全员生产维护体系' },
  ],
}

const mutations = {
  setSpecArrowImg: (state, payload) => {
    state.specArrowImg = payload
  },
  setScreenWidth: (state, payload) => {
    state.screenWidth = payload
  },
}

const actions = {
  sidebarStatus({ commit }) {
    commit('SIDEBAR_STATUS')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
