import { documentPreviewServices } from "@/utils/constants";
import { encode } from 'js-base64';
/**
 * @description: 获得上传文件对外开放的服务地址【处理文件查看地址】
 * @param {*} file
 * @return {*} serviceFileUrl
 */
export const handleServiceFileUrl = (file) => {
  let baseURL = handleDownloadUrl(file);
  const serviceFileUrl =
    documentPreviewServices + encodeURIComponent(encode(baseURL));
  return serviceFileUrl;
};


/**
 * @description: 处理下载文件/视频地址
 * @param {*} file
 * @return {*} baseURL
 */
export const handleDownloadUrl = (file) => {
  let baseURL = "";
  if (file.response) {
    baseURL = file.response.data.address;
  } else {
    baseURL = file.address;
  }
  return baseURL;
};
//根据 url和 名称 生成blob  下载 文件
export function handleFileDown(file, name = 'originalFileName') {
  let url = handleDownloadUrl(file);
  let fileName = file[name]
  getBlob(url).then((blob) => {
    downBlobFile(blob, fileName);
  });
}
/**
 * @description: 直接下载文件
 * @param {*} file
 * @return {*}
 */
export const handleDownloadFile = (file) => {
  let a = document.createElement("a");
  a.href = handleDownloadUrl(file);
  a.download = file.originalFileName;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  a.remove();
};
/**
 *  返回的文件流 进行下载
 * @param {*} res 后端返回文件流 blob格式
 */
export const fileStreamDownload = (fileStream) => {
  // const fileName = getSessionStorage(fileNameKey, true);
  const fileName = ['文件', '.xlsx'];
  let blob = new Blob([fileStream]);
  let a = document.createElement("a");
  const objectURL = window.URL.createObjectURL(blob);
  a.download = fileName[0] + "." + fileName[1];
  a.href = objectURL;
  a.click();
  URL.revokeObjectURL(objectURL);
  a = null;
};
//根据 url和 名称 生成blob 下载图片
export const downloadPic = (url, name) => {
  let image = new Image();
  image.setAttribute("crossOrigin", "anonymous");
  image.src = url;
  image.onload = () => {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, image.width, image.height);
    canvas.toBlob((blob) => {
      let url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.download = name;
      link.href = url;
      link.click();
      link.remove();
      // 用完释放URL对象
      URL.revokeObjectURL(url);
    });
  };
};

// 获取blob格式数据
function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
}
// 对blob格式文件 进行下载并改名
function downBlobFile(blob, filename, fileSuffixName) {
  const link = document.createElement("a");
  const body = document.querySelector("body");
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  // fix Firefox
  link.style.display = "none";
  body.appendChild(link);
  link.click();
  body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
}
// base64 格式 转 Blob对象
export const base64ToBlob = (code) => {
  var parts = code.split(";base64,");
  var contentType = parts[0].split(":")[1];
  var raw = window.atob(parts[1]);
  var rawLength = raw.length;
  var uint8Array = new Uint8Array(rawLength);
  for (var i = 0; i < rawLength; i++) {
    uint8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uint8Array], { type: contentType });
};
// base64 格式的 图片下载
export const base64ImgDown = (imgUrl = "") => {
  // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
  if (window.navigator.msSaveOrOpenBlob) {
    let bstr = atob(imgUrl.split(",")[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr]);
    window.navigator.msSaveOrOpenBlob(blob, "chart-download" + "." + "png");
  } else {
    // 这里就按照chrome等新版浏览器来处理
    let a = document.createElement("a");
    a.href = imgUrl;
    a.setAttribute("download", "chart-download");
    a.click();
  }
};
