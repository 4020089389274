
export function setSessionStorage(key, value, type) {
  if (type === 1) {
    sessionStorage.setItem(key, JSON.stringify(value));
    return;
  }
  sessionStorage.setItem(key, value);
}
export function getSessionStorage(key, type) {
  if (type === 1) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  return sessionStorage.getItem(key);
}
