<template>
  <div id="app">
    <Header />
    <div id="main">
      <div class="cs_container height_container">
        <router-view />
      </div>
      <Footer />
      <div id="back_to_top" ref="btn" @click="backTop" style="display: none">
        <p style="font-size: 0.625em; font-weight: bold">TOP</p>
        <img src="../assets/other/launch.png" style="height: 45px; width: 35px" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Footer from './components/Footer.vue';
import Header from "./components/Header.vue";
export default {
  name: "Layout",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      scroll: "",
      timer: "",
      currentMenu: "首页",
      screenWidth: document.body.clientWidth,
    };
  },
  methods: {
    ...mapMutations("website", ["setScreenWidth"]),
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 600) {
        //判断滚动后高度超过400px,就显示
        this.$refs.btn.style.display = "block";
      } else {
        this.$refs.btn.style.display = "none";
      }
    },
    handleResize() {
      window.screenWidth = window.innerWidth;
      this.screenWidth = window.screenWidth;
    },
    backTop() {
      //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
      this.timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer);
        }
      }, 30);
    },
  },
  mounted() {
    // this.menuSeen()
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    // 监听浏览器窗口宽度
    screenWidth: {
      handler(screenWidth) {
        console.log("视口宽度", screenWidth);
        this["setScreenWidth"](screenWidth);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

/* 默认 div字体大小16px */
div {
  font-size: 18px;
}

#app {
  width: 100%;

  // height: 100%;
  min-height: 100%;

  #main {
    position: relative;
    min-height: calc(100%);
    /* 最小宽度-最大宽度 */
    // min-width: 1000px;
    width: 100%;
    // padding: 0 3%;
    min-width: 1000px;
    max-width: 3000px;
    background-color: $page_bgc;
    margin: auto;
    height: 100%;
    box-sizing: border-box;

    .cs_container {
      clear: both;
      background-color: #fff;
    }

    #back_to_top {
      position: fixed;
      bottom: 50px;
      right: 8px;
      cursor: pointer;
    }
  }
}

/* ::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #999;
} */

::-webkit-scrollbar-track {
  background: $page_bgc;
  box-shadow: 0 0 5px #999;
}
</style>
