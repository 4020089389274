<!-- 安恪宗旨 -->
<template>
  <div class="home_purpose">
    <div class="left_content">
      <!--  <one-row-title title="安恪宗旨" margin="20px 0" /> -->
      <div class="purpose_content font_content" v-for="(i, index) in purposeContent" :key="index">
        {{ i }}
      </div>
    </div>
    <div class="right_video" v-if="videoUrl">
      <video-player style="width: 100%;height: 100%;margin:0 auto;box-shadow:5px 5px 8px #888888"
        class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
        :options="playerOptions"></video-player>
    </div>
  </div>
</template>

<script setup>
import { purposePagePost } from '@/api';
import { useSimpleApi } from '@/utils';
import { videoPlayerOptions } from '@/utils/constants';
import { onMounted, reactive, ref } from 'vue';
const videoUrl = ref('')
const playerOptions = reactive(videoPlayerOptions)
const purposeContent = ref([])
const getPurpose = () => {
  useSimpleApi(purposePagePost, data => {
    // console.log(data.rows[0].imagesVideo[0]);
    const videoInfo = data.rows[0]?.imagesVideo[0] || []
    const url = videoInfo.address
    // 换行-内容
    const content = data.rows[0]?.content.split('\n') ?? ''
    purposeContent.value = content
    playerOptions.sources[0].type = 'video/' + videoInfo.suff
    playerOptions.sources[0].src = url
    videoUrl.value = url
  })
}
onMounted(() => {
  getPurpose()
})
</script>

<style lang="scss" scoped>
.home_purpose {
  width: 100%;
  display: flex;
  padding-bottom: 10px;

  .left_content {
    width: 60%;
    background-color: rgba(177, 204, 255, .1);
    padding: 15px 0;

    .purpose_content {
      padding: 0 50px;
      font-size: 18px;
      color: #333333;
      font-weight: normal !important;
    }
  }

  .right_video {
    width: 40%;
  }


}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3 {
  height: 100% !important;
}

:deep .vjs-custom-skin>.video-js {
  height: 100% !important;
}
</style>