<!-- 优质服务 -->
<template>
  <div class="quality_service">
    <el-row :gutter="10">
      <el-col :xs="8" :sm="8" :lg="8" v-for="(i, index) in qualityServiceList" :key="index">
        <div class="service_main">
          <div class="service_img" style="cursor: pointer;">
            <!--  <el-image id="imageId" :style="{ height: imgHeight, width: '100%' }" -->
            <el-image id="imageId" :style="{ width: '100%', height: '100%' }" fit="contain" :src="i.atlas[0]?.address"
              @load="getImgHeight" @click="atlasTitleClick(i)">
            </el-image>
          </div>
          <div class="service_content">
            <div class="content_title small_title" style="cursor: pointer;" @click="atlasTitleClick(i)">{{ i.title
            }}</div>
            <div class="text_exceed_4row_ellipsis font_content" style="cursor: pointer;" @click="atlasTitleClick(i)">
              {{ i.content }}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { highqualitySelectmustPost } from '@/api';
import { useSimpleApi } from '@/utils';

export default {
  name: "",
  data() {
    return {
      qualityServiceList: [
      ],
      imgHeight: ''
    };
  },
  created() {
    this.getQualityService()
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.getImgHeight();
    }, false);
  },
  methods: {
    getQualityService() {
      useSimpleApi(highqualitySelectmustPost, (data) => {
        this.qualityServiceList = data.rows
      })
    },
    getImgHeight() {
      this.$nextTick(() => {
        let titleHeight = 0
        if (this.isTitle) {
          titleHeight = 50
        }
        const elements = document.querySelectorAll('#imageId')
        if (elements && elements.length) {
          // console.log(elements[0]);
          const height = elements[0].height + titleHeight
          if (height < 230) {
            this.imgHeight = 230
          } else {
            this.imgHeight = height
          }
        } else {
          this.imgHeight = ''
        }
        // console.log(this.imgHeight, 4);
      });
    },
    atlasTitleClick(i) {
      this.$router.push('/qualityServiceItem')
    }
  },
};
</script>

<style scoped lang="scss">
.quality_service {
  text-align: center;

  .service_main {
    flex: 1;

    .service_img {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }

    .service_content {
      margin-top: 15px;
      padding: 0 10px;

      .content_title {
        margin: 0 0 15px 0;
      }
    }
  }


}
</style>
