<template>
  <div class="cs_header">
    <span v-for="i in headerList" :key="i.id" @mouseenter="enterHeaderSpan(i)" @mouseleave="leaveHeaderSpan"
      :class="{ height_light: i.id === headerSpanObj.id, header_span: true }" @click="headerSpanClick(i)">
      {{ i.title }}</span>
    <el-collapse-transition>
      <div v-show="isShowHeaderMask" class="header_hover_mask" @mouseenter="enterHeaderMaskDiv(headerSpanObj)"
        :style="{ 'text-align': headerMaskTextPosition }" @mouseleave="leaveHeaderMaskDiv">
        <div v-show="headerSpanObj.title === '产品与解决方案'" style="height: 100%">
          <div v-if="showMaskPhoneMenu || true" style="height: 100%;">
            <el-tabs type="border-card" @tab-click="handleTabClick">
              <el-tab-pane v-for="(i) in technicalServiceList" :label="i[technicalServiceKey]"
                :key="i[technicalServiceKey]" />
            </el-tabs>
          </div>
          <div v-else style="text-align: left; height: 100%">
            <el-dropdown style="height: 100%; cursor: pointer" @command="handleHeaderCommand" :trigger="dropdownTrigger">
              <i class="el-icon-s-unfold" style="font-size: 50px; color: "></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="i in technicalServiceList" :key="i[technicalServiceKey]"
                  :command="i[technicalServiceKey]">{{
                    i[technicalServiceKey]
                  }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div v-show="headerSpanObj.title === '成果展示'" style="height: 100%">
          <span v-for="i in achievementDisplayList" :key="i.id" @click="headerMaskSpanClick(i)">
            {{ i.title }}</span>
          <!--       
          <el-tabs type="border-card" @tab-click="handleTabClick">
            <el-tab-pane v-for="i in achievementDisplayList" :label="i.title" :key="i.id" />
          </el-tabs> -->
        </div>
        <div v-show="headerSpanObj.title === '关于我们'">
          <span v-for="i in aboutUsList" :key="i.id" @click="headerMaskSpanClick(i)">
            {{ i.title }}</span>
        </div>
      </div>
    </el-collapse-transition>
    <span class="company_icon">
      <img src="../../assets/other/conpany.png" alt="" class="img_style">
    </span>
    <div class="company_title">
      上海安恪技术股份有限公司
    </div>
  </div>
</template>

<script>
import { technicalPagePost } from '@/api/index';
import { useSimpleApi } from '@/utils';
import { titleClickJumpPage } from '@/utils/titleClickJumpPage';
export default {
  name: "Header",
  data() {
    return {
      headerList: [
        { title: "首页", id: 1 },
        { title: "产品与解决方案", id: 2 },
        { title: "优质服务", id: 3 },
        { title: "成果展示", id: 4 },
        { title: "专家团队", id: 5 },
        { title: "关于我们", id: 7 },
        { title: "风采展示", id: 6 },
      ],
      headerSpanObj: { title: '产品与解决方案' },
      isShowHeaderMask: false, // 遮罩是否显示
      headerMaskTextPosition: "center",
      // 头部遮罩 显示的列表
      technicalServiceList: [], // 技术服务遮罩列表
      technicalServiceKey: 'title', // 技术服务 绑定的key字段
      achievementDisplayList: [
        { id: 1, title: "论文展示", type: 1 },
        { id: 2, title: "工作成果", type: 2 },
      ],
      aboutUsList: [
        { id: 1, title: "公司介绍" },
        { id: 2, title: "联系我们" },
        { id: 3, title: "加入我们" },
        { id: 4, title: "在线留言" },
      ],
    };
  },
  created() {
    // console.log(document.documentElement.clientWidth);
    this.getTechnicalServiceList()
    // this.technicalServiceList = this.$store.state.website.technicalServiceList;
  },
  computed: {
    // 屏幕太少时候菜单 不用了
    showMaskPhoneMenu() {
      if (this.$store.state.website.screenWidth > 1240) {
        return true;
      }
      return false;
    },
    dropdownTrigger() {
      if (this.$store.state.website.screenWidth > 1000) {
        return "hover";
      }
      return "click";
    },
  },
  methods: {
    openHeaderMask() {
      this.isShowHeaderMask = true;
    },
    closeHeaderMask() {
      this.isShowHeaderMask = false;
    },
    enterHeaderMethod(i) {
      switch (i.title) {
        case "产品与解决方案":
          this.openHeaderMask();
          break;
        case "成果展示":
          this.openHeaderMask();
          break;
        case "关于我们":
          this.openHeaderMask();
          break;
      }
    },
    enterHeaderSpan(i) {
      if (i.title === "关于我们") {
        this.headerMaskTextPosition = "center";
      } else {
        this.headerMaskTextPosition = "center";
      }
      this.headerSpanObj = i; // 控制头部高亮
      this.enterHeaderMethod(i);
    },
    enterHeaderMaskDiv(i) {
      this.enterHeaderMethod(i);
    },
    leaveHeaderSpan() {
      // 移出span的时候 如果未显示遮罩 就将span高亮 取消
      if (!this.isShowHeaderMask) {
        this.headerSpanObj = {};
      }
      this.closeHeaderMask();
    },
    leaveHeaderMaskDiv() {
      this.headerSpanObj = {};
      this.closeHeaderMask();
    },
    headerSpanClick(i) {
      const title = i.title
      switch (title) {
        case "首页":
          this.$router.push("/");
          break;
        case "优质服务":
          this.$router.push('/qualityServiceItem')
          break;
        case "专家团队":
          this.$router.push('/expertTeam')
          break;
        case "风采展示":
          this.$router.push('/mienDisplay')
          break;
      }
    },
    headerMaskSpanClick(i) {
      let headerTitle = this.headerSpanObj.title
      if (headerTitle === '关于我们') {
        headerTitle = i.title
      }
      switch (headerTitle) {
        case '产品与解决方案': titleClickJumpPage(i, 'TechnicalServiceItem', 'title'); break;
        case '成果展示': titleClickJumpPage(i, 'AchievementDisplay'); break;
        case '联系我们': this.$router.push('/contactUs'); break;
        case '加入我们': this.$router.push('/joinUs'); break;
        case '在线留言': this.$router.push('/onlineMessage'); break;
        case '公司介绍': this.$router.push('/companyIntroduction'); break;
        default: console.error('参数有问题！');
      }
    },

    handleHeaderCommand(command) {
      this.headerMaskSpanClick(command);
    },
    getTechnicalServiceList() {
      useSimpleApi(technicalPagePost, (data) => {
        this.technicalServiceList = data.rows
      })
    },
    handleTabClick(tab) {
      const index = tab.index
      this.headerMaskSpanClick(this.technicalServiceList[index])
    }
  },
};
</script>

<style scoped lang="scss">
$headerFontColor: #f2f2f2;
$headerHeight: 50px;

span {
  font-size: 18px !important;
}

.header_span {
  margin-right: 20px;
}

:deep .el-tabs__nav-scroll {
  display: flex;
}

@media (max-width: 1260px) {
  .company_title {
    display: none;
  }
}

/* @media (max-width: 460px) {
  span {
    font-size: 12px !important;
    padding: 0 4px !important;
  }
}

@media (min-width: 460px) and (max-width: 1316px) {
  span {
    font-size: 13px !important;
  }
} 
@media (min-width: 1316px) and (max-width: 1600px) {
  span {
    font-size: 15px !important;
  }
  .header_span {
    padding: 0 30px !important;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  span {
    font-size: 16px !important;
  }

  .header_span {
    padding: 0 40px !important;
  }
} */
:deep .el-tabs__nav-scroll {
  justify-content: center;
}

/* @media (max-width: 2000px) {}

// 控制技术服务的tab栏 居中( 全部居中会显示不全 )
@media (min-width: 1600px) {
  :deep .el-tabs__nav-scroll {
    justify-content: center;
  }
} */

.cs_header {
  min-width: 1000px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: $home_header_bgc;
  color: $headerFontColor;
  height: $headerHeight;
  line-height: $headerHeight;

  span {
    padding: 0 10px;
    height: 100%;
    vertical-align: middle;
  }

  .header_hover_mask {
    position: absolute;
    top: $headerHeight;
    left: 0;
    right: 0;
    height: 52px;
    z-index: 111;
    background-color: $home_hover_mask_bgc;
    border: 0;

    span:hover {
      cursor: pointer;
      color: $home_hover_color;
    }
  }

  .height_light {
    z-index: 112;
    cursor: pointer;
    border-bottom: 2px solid $home_hover_color;
    color: $home_hover_color;
  }

  .company_icon {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    height: 30px;
    width: 30px;
    margin: auto 0;
  }

  .company_title {
    position: absolute;
    left: 55px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 13px
  }
}

:deep .el-tabs--border-card {
  height: 100%;
  line-height: $headerHeight;
  vertical-align: middle;
  background-color: transparent;
  border: 0;
}

:deep .el-tabs--border-card>.el-tabs__header {
  border: 0;
  background-color: transparent;
}

:deep .el-tabs__content {
  padding: 0;
}

:deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  border: 0;
  color: $headerFontColor;
  padding: 0 40px 0 0 !important;

  &:hover {
    cursor: pointer;
    color: $home_hover_color;
  }
}

:deep .el-tabs--border-card>.el-tabs__header .el-tabs__item:first-child {
  padding-left: 10px !important;
}

:deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: transparent;
  color: $headerFontColor;

  &:hover {
    cursor: pointer;
    color: $home_hover_color;
  }
}



:deep .el-tabs__item {
  font-size: 18px;
}

:deep .el-tabs__nav-prev,
:deep .el-tabs__nav-next {
  height: 100%;
  line-height: $headerHeight;
  color: #fff;
}
</style>
