import request from '@/service';
import { BaseResponse } from '@/types/index';
import { trimStringInObject } from '@/utils/index';
//js无法处理后台传入的64位整形，会出现截取，后端会将其转成字符串传入
type Int64 = string;
//将Timestamp处理为 string类型
type Timestamp = string;
type List<T> = Array<T>;
type int = number;
type PageResult = any
export interface MessageBoardVO {
  /** 审核状态1：通过0：不通过 example: */
  auditStatus?: number;
  /** 内容 example: */
  content?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 留言人 example: */
  messageRecipient?: string;
  /** 预览 example: */
  preview?: string;
  /** 留言时间 example: */
  recipientTime?: string;
  /** 回复内容 example: */
  respondentInfo?: string;
  /** 回复时间 example: */
  respondentTime?: string;
  /** 动态字段 example: */
  trends?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}
export interface BaseException {
  /**  example: */
  bizCode?: string;
  /**  example: */
  cause?: Throwable;
  /**  example: */
  localizedMessage?: string;
  /**  example: */
  message?: string;
  /**  example: */
  stackTrace?: StackTraceElement[];
  /**  example: */
  suppressed?: Throwable[];
}

export interface Throwable {
  /**  example: */
  cause?: Throwable;
  /**  example: */
  localizedMessage?: string;
  /**  example: */
  message?: string;
  /**  example: */
  stackTrace?: StackTraceElement[];
  /**  example: */
  suppressed?: Throwable[];
}

export interface StackTraceElement {
  /**  example: */
  className?: string;
  /**  example: */
  fileName?: string;
  /**  example: */
  lineNumber?: number;
  /**  example: */
  methodName?: string;
  /**  example: */
  nativeMethod?: boolean;
}

export interface Paging {
  /**  example: */
  pageNum?: number;
  /**  example: */
  pageSize?: number;
  /**  example: */
  total?: Int64;
}

export interface PageEntity {
  /**  example: */
  pageNum?: number;
  /**  example: */
  pageSize?: number;
}

export interface Attachment {
  /** 上传url example: */
  address?: string;
  /** 是否删除1是，0否 example: */
  deleteFlag?: number;
  /** 文件名 example: */
  fileName?: string;
  /** 文件大小 example: */
  fileSize?: number;
  /** id example: */
  id?: Int64;
  /** 原始文件名 example: */
  originalFileName?: string;
  /** 相对路径 example: */
  path?: string;
  /** 文件后缀 example: */
  suff?: string;
  /** 上传人id example: */
  uploadId?: string;
  /** 上传时间 example: */
  uploadTime?: string;
}

export interface AchievementDisplayVO {
  /** 附件 example: */
  annex?: Attachment[];
  /** 附件名称 example: */
  annexName?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 关键字 example: */
  keywords?: string;
  /** 名称/标题 example: */
  name?: string;
  /** 预览 example: */
  preview?: string;
  /** 1:论文展示2：工作成果3：资料下载4：公司资料 example: */
  type?: number;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface AbsCompanyProductVO {
  /** 主表id example: */
  companyId?: Int64;
  /** 内容 example: */
  content?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 介绍 example: */
  introduce?: string;
  /** 预览 example: */
  preview?: string;
  /** 标题/名称 example: */
  title?: string;
  /** 1：公司发展2： 组织机构:3：产品目录 example: */
  type?: number;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
  /** 年份 example: */
  year?: string;
}

export interface ConsultationUpdatesVO {
  /** 内容 example: */
  content?: string;
  /** 封面 example: */
  cover?: Attachment[];
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 站外链接 example: */
  externalLinks?: string;
  /** 主键id example: */
  id?: Int64;
  /** 链接类型 1：站内链接 2：站外链接 example: */
  linksType?: number;
  /** 预览 example: */
  preview?: string;
  /** 发布状态 1：立即发布2：定时发布 example: */
  releaseType?: number;
  /** 定时发布时间 example: */
  scheduledReleaseTime?: string;
  /** 副标题 example: */
  subtitle?: string;
  /** 标题 example: */
  title?: string;
  /** 1：公司新闻2：行业动态3：通知公告4:项目总结 example: */
  trendsType?: number;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface ContactUsVO {
  /** 地址 example: */
  address?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 邮箱 example: */
  email?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片 example: */
  images?: string;
  /** 介绍 example: */
  introduce?: string;
  /** 名称 example: */
  name?: string;
  /** 手机 example: */
  phone?: Int64;
  /** 预览 example: */
  preview?: string;
  /** 类型：1：上半部分2：下半部分 example: */
  type?: number;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface AbsExpertsVO {
  /** 介绍 example: */
  content?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 是否展示 1:是 2:否 example: */
  ifshow?: number;
  /** 照片 example: */
  images?: Attachment[];
  /** 姓名 example: */
  name?: string;
  /** 职位 example: */
  position?: string;
  /** 预览 example: */
  preview?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface HighQualityServiceVO {
  /** 图片 example: */
  atlas?: Attachment[];
  /** 图册标题 example: */
  atlasTitle?: string;
  /** 内容简介 example: */
  content?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片数量 example: */
  imageNumber?: number;
  /** 预览 example: */
  preview?: string;
  /** 主表id example: */
  qualityServiceId?: Int64;
  /** 副标题 example: */
  subtitle?: string;
  /** 标题 example: */
  title?: string;
  /** 类型：1:咨询评估2：技术支持 example: */
  type?: number;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface AbsHonorVO {
  /** 图册 example: */
  atlas?: Attachment[];
  /** 图册标题 example: */
  atlasTitle?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 预览 example: */
  preview?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface JoinUsVO {
  /** 内容 example: */
  content?: Attachment[];
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 预览 example: */
  preview?: string;
  /** 标题 example: */
  title?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface AbsHpPartnersVO {
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片 example: */
  images?: Attachment[];
  /** 预览 example: */
  preview?: string;
  /** 标题 example: */
  title?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface AbsPictureVO {
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片 example: */
  picture?: string;
  /** 预览 example: */
  preview?: string;
  /** 标题 example: */
  title?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface AbsPurposeVO {
  /** 内容 example: */
  content?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片/视频 example: */
  imagesVideo?: string;
  /** 预览 example: */
  preview?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface RotationChartVO {
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片 example: */
  picture?: Attachment[];
  /** 预览 example: */
  preview?: string;
  /** 标题 example: */
  title?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

export interface StyleDisplayTeamVO {
  /** 内容 example: */
  conten?: string;
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 图片 example: */
  images?: Attachment[];
  /** 预览 example: */
  preview?: string;
  /** 副标题 example: */
  subtitle?: string;
  /** 标题 example: */
  title?: string;
  /** 类型 1：风采展示 2：团队介绍 example: */
  type?: number;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
  /** 视频 example: */
  video?: string;
}

export interface TechnicalServiceVO {
  /** 创建人id example: */
  createId?: Int64;
  /** 创建时间 example: */
  createTime?: string;
  /** 主键id example: */
  id?: Int64;
  /** 预览 example: */
  preview?: string;
  /** 解决方案 example: */
  solution?: string;
  /** 团队介绍 example: */
  teamIntroduction?: string;
  /** 标题 example: */
  title?: string;
  /** 更新人id example: */
  updateId?: Int64;
  /** 更新时间 example: */
  updateTime?: string;
  /** 上传人 example: */
  username?: string;
}

/** 用户名密码登录返回信息V0 */
export interface UserLoginVo {
  /** 头像 example: */
  headUrl?: string;
  /** 主键id example: */
  id?: Int64;
  /** 登录账号 example: */
  loginName?: string;
  /** token example: */
  token?: string;
  /** 用户名 example: */
  username?: string;
}
/** 管理体系查询 */
export async function managementsysSelectPost(
  data: unknown,
): Promise<BaseResponse<any>> {
  return request(`/managementsys/select`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 留言板信息分页查询接口 */
export async function messagePagePost(
  data: unknown,
): Promise<BaseResponse<MessageBoardVO>> {
  return request(`/message/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 资料下载-成果展示新增 */
export async function achievementAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/achievement/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 资料下载-成果展示删除接口 */
export async function achievementDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/achievement/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 资料下载-成果展示批量删除接口 */
export async function achievementDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/achievement/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 资料下载-成果展示分页查询接口 */
export async function achievementPagePost(
  data: unknown,
): Promise<BaseResponse<AchievementDisplayVO>> {
  return request(`/achievement/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 资料下载-成果展示修改 */
export async function achievementUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/achievement/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 公司-产品-组织新增 */
export async function companyproductAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/companyproduct/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 公司-产品-组织删除接口 */
export async function companyproductDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/companyproduct/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 公司-产品-组织批量删除接口 */
export async function companyproductDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/companyproduct/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 公司-产品-组织分页查询 */
export async function companyproductPagePost(
  data: unknown,
): Promise<BaseResponse<AbsCompanyProductVO>> {
  return request(`/companyproduct/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 固定主表提交 */
export async function companyproductSelectPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/companyproduct/select`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 固定主表查询 */
export async function companyproductSelectmustPost(
  data: unknown,
): Promise<BaseResponse<AbsCompanyProductVO>> {
  return request(`/companyproduct/selectmust`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 公司-产品-组织修改 */
export async function companyproductUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/companyproduct/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 咨询动态新增 */
export async function consultationAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/consultation/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 咨询动态删除接口 */
export async function consultationDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/consultation/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 咨询动态批量删除接口 */
export async function consultationDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/consultation/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 咨询动态分页查询接口 */
export async function consultationPagePost(
  data: unknown,
): Promise<BaseResponse<ConsultationUpdatesVO>> {
  return request(`/consultation/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 咨询动态修改 */
export async function consultationUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/consultation/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 联系我们新增接口 */
export async function contactAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/contact/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 联系我们删除接口 */
export async function contactDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/contact/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 联系我们批量删除接口 */
export async function contactDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/contact/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 邮箱地址修改 */
export async function contactEmupdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/contact/emupdate`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 联系我们分页查询接口 */
export async function contactPagePost(
  data: unknown,
): Promise<BaseResponse<ContactUsVO>> {
  return request(`/contact/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 联系我们修改 */
export async function contactUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/contact/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 首席专家新增接口 */
export async function expertsAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/experts/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 首席专家删除接口 */
export async function expertsDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/experts/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 首席专家批量删除接口 */
export async function expertsDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/experts/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 首席专家分页查询接口 */
export async function expertsPagePost(
  data: unknown,
): Promise<BaseResponse<AbsExpertsVO>> {
  return request(`/experts/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 首席专家修改 */
export async function expertsUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/experts/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 优质服务新增 */
export async function highqualityAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/highquality/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 优质服务删除接口 */
export async function highqualityDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/highquality/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 优质服务批量删除接口 */
export async function highqualityDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/highquality/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 优质服务分页查询 */
export async function highqualityPagePost(
  data: unknown,
): Promise<BaseResponse<HighQualityServiceVO>> {
  return request(`/highquality/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 固定主表查询 */
export async function highqualitySelectmustPost(
  data: unknown,
): Promise<BaseResponse<HighQualityServiceVO>> {
  return request(`/highquality/selectmust`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 固定主表提交 */
export async function highqualitySubmitPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/highquality/submit`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 优质服务修改 */
export async function highqualityUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/highquality/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 荣誉新增接口 */
export async function honorAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/honor/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 荣誉删除接口 */
export async function honorDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/honor/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 荣誉批量删除接口 */
export async function honorDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/honor/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 荣誉分页查询接口 */
export async function honorPagePost(data: unknown): Promise<BaseResponse<AbsHonorVO>> {
  return request(`/honor/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 荣誉修改 */
export async function honorUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/honor/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 加入我们新增接口 */
export async function joinAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/join/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 加入我们删除接口 */
export async function joinDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/join/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 加入我们批量删除接口 */
export async function joinDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/join/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 加入我们分页查询接口 */
export async function joinPagePost(data: unknown): Promise<BaseResponse<JoinUsVO>> {
  return request(`/join/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 加入我们修改 */
export async function joinUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/join/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 留言板设置分页查询接口 */
export async function messagesetPagePost(
  data: unknown,
): Promise<BaseResponse<any>> {
  return request(`/messageset/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 留言板新增接口 */
export async function messageAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/message/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 留言板信息批量删除接口 */
export async function messageDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/message/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 留言板审核接口 */
export async function messageExaminePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/message/examine`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 文件上传 */
export async function minioUploadPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/minio/upload`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** addAbsHpPartners */
export async function partnersAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/partners/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 合作伙伴删除接口 */
export async function partnersDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/partners/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 合作伙伴批量删除接口 */
export async function partnersDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/partners/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 合作伙伴分页查询接口 */
export async function partnersPagePost(
  data: unknown,
): Promise<BaseResponse<AbsHpPartnersVO>> {
  return request(`/partners/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 合作伙伴修改 */
export async function partnersUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/partners/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 图片新增接口 */
export async function pictureAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/picture/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
// 
/** 技术服务详情接口  */
export async function technicalInfo(data: unknown): Promise<BaseResponse<any>> {
  return request(`/technical/info`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 图片删除接口 */
export async function pictureDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/picture/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 图片批量删除接口 */
export async function pictureDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/picture/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 图片分页查询接口 */
export async function picturePagePost(
  data: unknown,
): Promise<BaseResponse<any>> {
  return request(`/picture/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 首席专家分页查询接口 */
export async function chiefPagePost(
  data: unknown,
): Promise<BaseResponse<any>> {
  return request(`/chief/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 图片修改接口 */
export async function pictureUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/picture/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 安恪宗旨分页查询接口 */
export async function purposePagePost(
  data: unknown,
): Promise<BaseResponse<AbsPurposeVO>> {
  return request(`/purpose/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 安恪宗旨修改 */
export async function purposeUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/purpose/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 轮播图新增 */
export async function rotationAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/rotation/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 轮播图删除接口 */
export async function rotationDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/rotation/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 轮播图批量删除接口 */
export async function rotationDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/rotation/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 轮播图分页查询接口 */
export async function rotationPagePost(
  data: unknown,
): Promise<BaseResponse<RotationChartVO>> {
  return request(`/rotation/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 轮播图修改 */
export async function rotationUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/rotation/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 风采展示新增 */
export async function styledisplayAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/styledisplay/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 风采展示删除接口 */
export async function styledisplayDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/styledisplay/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 风采展示批量删除接口 */
export async function styledisplayDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/styledisplay/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 风采-团队分页查询接口 */
export async function styledisplayPagePost(
  data: unknown,
): Promise<BaseResponse<StyleDisplayTeamVO>> {
  return request(`/styledisplay/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 风采-团队修改 */
export async function styledisplayUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/styledisplay/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 技术服务新增 */
export async function technicalAddPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/technical/add`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 技术服务删除接口 */
export async function technicalDeleteGet(params: {
  /** id */
  id: Int64;
}): Promise<BaseResponse<any>> {
  return request(`/technical/delete`, {
    method: 'GET',
    params: trimStringInObject(params),
  });
}

/** 技术服务批量删除接口 */
export async function technicalDeletelistPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/technical/deletelist`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 查询详情 */
export async function highqualitySelectallPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/highquality/selectall`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
/** 技术服务分页查询接口 */
export async function technicalPagePost(
  data: unknown,
): Promise<BaseResponse<TechnicalServiceVO>> {
  return request(`/technical/page`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 技术服务修改 */
export async function technicalUpdatePost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/technical/update`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 测试接口loginPath */
export async function testLoginGet(): Promise<BaseResponse<object>> {
  return request(`/test/Login`, {
    method: 'GET',
  });
}

/** 测试接口高德地图 */
export async function testGdmapGet(): Promise<BaseResponse<object>> {
  return request(`/test/gdmap`, {
    method: 'GET',
  });
}

/** 用户密码登录 */
export async function userLoginPost(data: unknown): Promise<BaseResponse<UserLoginVo>> {
  return request(`/user/login`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 退出登录 */
export async function userLogoutPost(data: unknown): Promise<BaseResponse<string>> {
  return request(`/user/logout`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}

/** 修改密码 */
export async function userPasswordPost(data: unknown): Promise<BaseResponse<any>> {
  return request(`/user/password`, {
    method: 'POST',
    data: trimStringInObject(data),
  });
}
