<template>
  <div class="ac_image_preview" :style="{ 'z-index': zIndex }">
    <div class="preview_img">
      <img :src="previewUrl" style="max-height: 100%;max-width: 100%;" />
      <div class="img-button-prev">
        <i class="el-icon-arrow-left" @click="handlePrevImg"></i>
      </div>
      <div class="img-button-next">
        <i class="el-icon-arrow-right" @click="handleNextImg"></i>
      </div>
    </div>
    <div class="close_icon" @click.stop="closePreview">
      <i class="el-icon-error"></i>
    </div>
    <div class="preview_list" :style="list.length > number ? 'padding: 0 30px;' : ''">
      <div class="preview_imgs_main">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(i, index) in list">
            <el-image :class="{ 'hover_img': true, 'current_scale': index === currentIndex }" fit="cover" :src="i"
              @click="imgListClick(i, index)" />
          </div>
        </div>
      </div>
      <div class="swiper-button-prev1" v-if="list.length > number">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="swiper-button-next1" v-if="list.length > number">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
/* 引入swiper插件 */
import Swiper from "swiper";
export default {
  props: {
    zIndex: {
      type: Number,
      default: 100
    },
    visible: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 20
    },
    // 地址组成的集合
    list: {
      required: true,
      type: Array,
      default: () => [
      ]
    }
  },
  data() {
    return {
      previewUrl: '',
      currentIndex: 0
    }
  },
  created() {
    this.previewUrl = this.list[0] ?? ''
    this.currentIndex = 0
  },
  mounted() {
    this.bannerPoint()
  },
  methods: {
    closePreview() {
      this.$emit('update:visible', false)
    },
    imgListClick(i, index) {
      this.currentIndex = index
      this.previewUrl = i
    },
    handlePrevImg() {
      if (this.currentIndex <= 0) {
        this.$message.warning('这是第一张图片！')
      } else {
        const index = this.currentIndex - 1
        this.previewUrl = this.list[index]
        this.currentIndex = index
      }
    },
    handleNextImg() {
      if (this.currentIndex >= this.list.length - 1) {
        this.$message.warning('这是最后一张图片！')
      } else {
        const index = this.currentIndex + 1
        this.previewUrl = this.list[index]
        this.currentIndex = index
      }
    },
    bannerPoint() {
      new Swiper(".preview_imgs_main", {
        observer: true,
        observeSlideChildren: true,
        observeParents: true,  //这三个可以去文档看下介绍，主要就是异步情况会重新加载swiper。
        loop: false,
        speed: 0,// 每个轮播图过渡的时间
        spaceBetween: 30, // 轮播图间隔
        autoplay: false,
        updateOnImagesReady: true,
        pdateOnWindowResize: false,  //分辨率改变时，防止样式错乱加上！
        slidesPerView: this.number,  //设置slider容器能够同时显示的slides数量
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next1', // 右按钮
          prevEl: '.swiper-button-prev1', // 左按钮
        },
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.ac_image_preview {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(63, 63, 63, .8);
  min-width: 1300px;
  z-index: 12000;

  .close_icon {
    position: absolute;
    right: 10px;
    top: 50px;
    font-size: 50px;
    color: #fff;
    cursor: pointer;
  }

  .preview_img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 140px);
    padding: 0 70px;
    position: relative;

    .img-button-prev,
    .img-button-next {
      position: absolute;
      top: 0;
      bottom: 0;
      // background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      font-size: 40px;
      color: #fff;
      cursor: pointer;
    }

    .img-button-prev {
      left: 0;
    }

    .img-button-next {
      right: 0;
    }
  }

  .preview_list {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    height: 80px;
    box-sizing: border-box;

    .preview_imgs_main {
      overflow: hidden;

      .swiper-wrapper {
        justify-content: center;

        .hover_img {
          width: 100%;
          height: 80px;
          // max-height: 80px;
          cursor: pointer;

          &:hover {
            scale: 1.4;
          }
        }
      }
    }




    .swiper-button-prev1,
    .swiper-button-next1 {

      width: 30px;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 11111;
      display: flex;
      align-items: center;
      font-size: 30px;
      color: #fff;
      cursor: pointer;
    }

    .swiper-button-prev1 {
      left: 0px;
    }

    .swiper-button-next1 {
      right: 0;
    }
  }

  .current_scale {
    scale: 1.4;
    // height: 90px !important;
  }
}
</style>