<!-- 技术服务 -->
<template>
  <div class="technical_service">
    <el-row class="cs_service_row">
      <el-col :xs="6" :sm="6" :lg="6" v-for="i in technicalServiceList" :key="i.title" class="cs_service_col">
        <div class="technical_service_item">
          <div class="cs_round" @click="handleClick(i)"></div>
          <div class="cs_service_title text_exceed_1row_ellipsis" @click="handleClick(i)">
            {{ i.title }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { technicalPagePost } from '@/api';
import { useSimpleApi } from '@/utils';
import { titleClickJumpPage } from '@/utils/titleClickJumpPage';
export default {
  name: "",
  data() {
    return {
      technicalServiceList: [],
    };
  },
  created() {
    this.getHomeTechnicalService()
  },
  methods: {
    handleClick(i) {
      console.log(i);
      titleClickJumpPage(i, 'TechnicalServiceItem', 'title')
    },
    async getHomeTechnicalService() {
      useSimpleApi(technicalPagePost, (data) => {
        this.technicalServiceList = data.rows
      })
    },
  },
};
</script>

<style scoped lang="scss">
.technical_service {
  display: flex;
  justify-content: center;

  .cs_service_row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .cs_service_col {
      margin: 0 0 30px 0;

      .technical_service_item {
        display: flex;
        justify-content: center;
        align-items: center;

        .cs_round {
          height: 40px;
          width: 40px;
          // border-radius: 50%;
          // background-color: rgb(85, 85, 85);
          background-image: url('../../../assets/index/technicalService.png');
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 10px;
          cursor: pointer;
          font-weight: 700;
        }

        .cs_service_title {
          /* 16px大小的字体 显示正常 */
          min-width: 150px;
          cursor: pointer;
          color: $big_title_text_color;
          font-weight: 700;
        }
      }
    }
  }

  .cs_service_row .cs_service_col:last-child {
    margin: 0;
  }
}
</style>
