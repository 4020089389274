<template>
  <div class="one_row_title" :style="{ margin: margin }">
    <div class="cs_line"></div>
    <div class="title_style">{{ title }}</div>
    <div class="cs_line"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    title: {
      type: String,
      default: "一站式解决方案",
    },
    margin: {
      type: String,
      default: "40px 0",
    }
  },
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>

<style scoped lang="scss">
.one_row_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;


  .title_style {
    font-size: 1.8em;
    color: $big_title_text_color;
    font-weight: 700;
    min-width: 150px;
    text-align: center;
  }

  .cs_line {
    margin: 0 25px;
    width: 40px;
    height: 2px;
    background-color: rgb(121, 121, 121);
  }
}
</style>
