import router from '@/router/index';
import { setSessionStorage } from '@/utils/storage';
export const titleClickJumpPage = (i, name: string = 'TechnicalServiceItem', encodeField: string = 'id') => {
  setSessionStorage(name, i, 1)
  router.push({
    // path: '/technicalServiceItem',
    name: name,
    params: i,
    query: {
      id: encodeField === 'id' ? i.id : encodeURIComponent(i[encodeField]),
    }
  })
}