const getters = {
  sidebarStatus: (state) => state.app.sidebarStatus,
  drawerStatus: (state) => state.app.drawerStatus,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  roles: (state) => state.user.roles,
  routers: (state) => state.user.routers,
  buttonAuthList: (state) => state.user.userInfo.buttonAuthList,
  userInfo:(state) => state.user.userInfo
};
export default getters;
