<template>
  <div class="ac-image">
    <el-image :class="imageClass" :src="src" :zoom-rate="zoomRate" :preview-src-list="previewSrcList" :z-index="999"
      :preview-teleported="true" :lazy="lazy" :hide-on-click-modal="hideOnClickModal" :initial-index="initialIndex"
      :close-on-press-escape="closeOnPressEscape" />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
defineProps({
  previewSrcList: {
    type: Array,
    default: []
  },
  // 图片源地址
  src: {
    type: String,
    default: ''
  },
  zoomRate: {
    type: Number,
    default: 1.2
  },
  lazy: {
    type: Boolean,
    default: false
  },
  hideOnClickModal: {
    type: Boolean,
    default: true
  },
  initialIndex: {
    type: Number,
    default: 0
  },
  closeOnPressEscape: {
    type: Boolean,
    default: true
  },
  imageClass: {
    type: String,
    default: ''
  }
})
onMounted(() => { })
</script>

<style scoped lang='scss'>
.ac-image {
  width: 100%;
  height: 100%;
}
</style>