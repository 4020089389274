
export * from './useApiCorrelate'
export const trimStringInObject = (obj: any) => {
  const newObj: any = obj

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key]
      if (typeof element === 'string') {
        newObj[key] = element.trim()
      } else {
        newObj[key] = element
      }
    }
  }
  return newObj
}
export const subLastOfStr = (str: string, key) => {
  return str.slice(str.lastIndexOf(`${key}`) + 1);
};
export const uuid = () => Math.random().toString(24).substring(2, 12);
/**
 * 一维数组 转为指定 数组元素个数的 二维数组
 * @param arr 
 * @param num 二维数组内 一维数组内元素个数
 * @param eleNum 数组元素的个数 划分数
 * @returns 
 */
export const convertTwoDimensionalArr = (arr: [], num: number = 2, eleNum: number = 2) => {
  const len = arr.length
  const arrNum = len % eleNum === 0 ? len / eleNum : Math.floor((len / eleNum) + 1)  //得出多少份
  const res: any = [] //定义数组接受最终的数据
  for (let i = 0; i < arrNum; i++) {
    const newArr: any = arr.slice(i * num, i * num + num) //得到每份的数据
    res.push(newArr) //往res数组里加数据
  }
  return res
}
export const previewAnnex = (file) => {
  openWindow(file.address, file.originalFileName)
}
/**
 * 
 * @param url 地址
 * @param title 窗口名称
 */
function openWindow(url: string, title: string = '窗口名称') {
  const newWindow: any = window.open('about:blank', title);
  newWindow.document.title = title;
  let iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.width = '100%';
  iframe.style.height = '100vh';
  iframe.style.margin = '0';
  iframe.style.padding = '0';
  iframe.style.overflow = 'hidden';
  iframe.style.border = 'none';
  newWindow.document.body.style.margin = '0';
  newWindow.document.body.appendChild(iframe);
}
