import Vue from 'vue'
import Router from 'vue-router'
import Home from '../pages/Home'
Vue.use(Router)
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/h-index.html',
      name: 'Home',
      component: Home,
    },
    {
      path: '/technicalServiceItem',
      name: 'TechnicalServiceItem',
      component: () => import('@/pages/TechnicalServiceItem'),
      cnName: '技术服务每一项'
    },
    {
      path: '/qualityServiceItem',
      name: 'QualityServiceItem',
      component: () => import('@/pages/QualityServiceItem'),
      cnName: '优质服务每一项'
    },
    {
      path: '/consultationTrendsItem',
      name: 'ConsultationTrendsItem',
      component: () => import('@/pages/ConsultationTrendsItem'),
      cnName: '咨询动态每一项'
    },
    {
      path: '/consultationTrendsMore',
      name: 'ConsultationTrendsMore',
      component: () => import('@/pages/ConsultationTrendsMore'),
      cnName: '咨询动态更多'
    },
    {
      path: '/achievementDisplay',
      name: 'AchievementDisplay',
      component: () => import('@/pages/AchievementDisplay'),
      cnName: '成果展示'
    },
    {
      path: '/expertTeam',
      name: 'ExpertTeam',
      component: () => import('@/pages/ExpertTeam'),
      cnName: '专家团队'
    },
    {
      path: '/mienDisplay',
      name: 'MienDisplay',
      component: () => import('@/pages/MienDisplay'),
      cnName: '风采展示'
    },
    {
      path: '/contactUs',
      name: 'ContactUs',
      component: () => import('@/pages/AboutUs/pages/ContactUs'),
      cnName: '关于我们-联系我们'
    },
    {
      path: '/moreLocation',
      name: 'MoreLocation',
      component: () => import('@/pages/AboutUs/pages/ContactUs/MoreLocation'),
      cnName: '关于我们-联系我们-各办公室地点'
    },
    {
      path: '/joinUs',
      name: 'JoinUs',
      component: () => import('@/pages/AboutUs/pages/JoinUs'),
      cnName: '关于我们-加入我们'
    },
    {
      path: '/onlineMessage',
      name: 'OnlineMessage',
      component: () => import('@/pages/AboutUs/pages/OnlineMessage'),
      cnName: '关于我们-在线留言'
    },
    {
      path: '/companyIntroduction',
      name: 'CompanyIntroduction',
      component: () => import('@/pages/AboutUs/pages/CompanyIntroduction'),
      cnName: '关于我们-公司简介'
    },
    { path: "/:catchAll(.*)", redirect: "/", }
  ],
})
