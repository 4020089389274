import axios from 'axios';
import { Message } from 'element-ui';
export const baseURL = process.env.VUE_APP_BASE_API
// 创建axios实例
const service = axios.create({
  baseURL,
  timeout: 10000, // 请求超时时间
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  (response) => {

    const res = response.data
    const code = res?.code
    const message = res?.message
    if (code === 200) {
      return res?.data
    } else {
      Message.warning(message)
      return Promise.reject(res)
    }
  },
  (error) => {
    Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
export function request(obj) {
  const settings = {
    method: 'POST',
    ...obj,
  }
  // from ture 标识params参数
  settings[obj.method ? 'params' : 'data'] = obj.data || {}
  return service(settings)
}
