<!-- 资料下载 -->
<template>
  <div class="data_down">
    <el-row>
      <el-col :xs="24" :sm="12" :lg="12" v-for="i in dataDownList" :key="i.id">
        <div class="main_content">
          <div class="left_content">
            <span class="file_img">
              <img style="width: 100%;" src="../../../assets/index/file.jpg" alt="文件破损">
            </span>
            <div class="file_name">
              {{ i.originalFileName }}
            </div>
          </div>
          <div class="right_content">
            <span @click="previewAnnex(i)">
              <img style="width: 100%;" src="../../../assets/index/car.svg" alt="文件破损">
            </span>
            <span style="margin-left: 15px;" @click="handleDown(i)">
              <img style="width: 100%;" src="../../../assets/index/down.svg" alt="文件破损">
            </span>

          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { achievementPagePost } from '@/api';
import { useSimpleApi } from '@/utils';
import { handleFileDown } from '@/utils/downLoadFile';
import { previewAnnex } from '@/utils/index';
import { onMounted, ref } from 'vue';
const dataDownList = ref([])
const getDataDownList = () => {
  useSimpleApi(achievementPagePost, data => {
    const list = []
    data.rows.forEach(i => {
      i.annex.forEach(i => {
        list.push(i)
      })
    })
    dataDownList.value = list
  }, { type: 3 })
}
const handleDown = (i) => {
  handleFileDown(i)

}
onMounted(() => {
  getDataDownList()
})
</script>

<style scoped lang='scss'>
.data_down {
  width: 100%;

  .main_content {

    display: flex;
    padding: 10px 20px;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
    justify-content: space-between;

    .left_content {
      width: calc(100% - 90px);
      display: flex;
      height: 50px;
      line-height: 50px;

      .file_img {
        width: 50px;
      }

      .file_name {
        margin-left: 10px;
        flex: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .right_content {
      width: 90px;
      height: 50px;
      display: flex;
      align-items: center;
      vertical-align: middle;

      span {
        width: 35px;
        cursor: pointer;
      }
    }
  }


}

.data_down .main_content:nth-child(n+1) {
  border-top: 0;
}
</style>