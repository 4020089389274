export const useSimpleApi = async (api: Function, callback?: Function, requestParams?: object,) => {
  try {
    const values = requestParams ?? {}
    const params = {
      pages: {
        pageNum: 1,
        pageSize: 100
      },
      ...values
    }
    const data = await api(params)
    callback && callback(data)
  } catch (e) {
    /* callback && callback(1) */
    console.log(e);
  }
}
