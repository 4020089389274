<template>
  <div class="cs_footer">
    <p class="footer_police">
      <span class="police_record_icon"></span>
      <a href="javascript:;">公安备案号31010402005772</a>
    </p>
    <p class="footer_copyright">
      Copyright © {{ new Date().getFullYear() - 6 }}-{{ new Date().getFullYear() }} ShangHaiAnchorTech Co.Ltd.
      上海安恪技术股份有限公司 版权所有
    </p>
    <p class="footer_filings">
      <span>工信部备案号 </span>
      <span @click="handleClickCode" style="cursor: pointer;">沪ICP备17028575号</span>
    </p>
    <div class="footer_admin" @click="jumpAdmin">管理>></div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => { })
const handleClickCode = () => {
  window.open(process.env.VUE_APP_FILINGS_URL)
}
const jumpAdmin = () => {
  console.log(process.env.VUE_APP_ADMIN_URL);
  window.open(process.env.VUE_APP_ADMIN_URL)
}
</script>

<style scoped lang='scss'>
.cs_footer {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 110px;
  // margin: 20px auto
  background-color: rgb(34, 34, 34);
  color: #999;
  font-size: 12px;
  text-align: center;

  .footer_police {
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px 0 0;
    text-align: center;

    .police_record_icon {
      background-image: url('../../assets/other/national_emblem.png');
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 14px;
      width: 14px;
    }

    a {
      color: #999;
      font-size: 12px;
      margin-left: 4px;
      text-decoration: none;
    }
  }

  .footer_copyright {
    margin: 8px 0 16px;
  }

  .footer_filings {
    color: rgb(73, 110, 232);
  }

  .footer_admin {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 12px;
    color: rgb(73, 110, 232);
    cursor: pointer;
  }
}
</style>