const state = {
  sidebarStatus: false,
  drawerStatus: false,
}

const mutations = {
  SIDEBAR_STATUS: (state) => {
    state.sidebarStatus = !state.sidebarStatus
  },
  DRAWER_STATUS: (state) => {
    state.sidebarStatus = false
    state.drawerStatus = !state.drawerStatus
  },
}

const actions = {
  sidebarStatus({ commit }) {
    commit('SIDEBAR_STATUS')
  },
  drawerStatus({ commit }) {
    commit('DRAWER_STATUS')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
